<template>
    <g-gantt-chart
        :chart-start="chartStart"
        :chart-end="chartEnd"
        :color-scheme="dark ? 'dark' : 'default'"
        :date-format="dateFormat"
        :grid="grid"
        :hideTimeaxis="hideTimeaxis"
        :precision="precision"
        :theme="dark ? 'dark' : 'default'">
        <template v-slot:[`upper-timeunit`]>
            <slot name="title"></slot>
        </template>
        <g-gantt-row
            v-for="(row, index) in rows"
            :key="index"
            :label="row.label"
            :bars="row.bars"
            bar-start="startOn"
            bar-end="endOn">
            <template v-slot:label>
                <slot name="rowLabel" v-bind:data="row"></slot>
            </template>
        </g-gantt-row>
    </g-gantt-chart>
</template>

<script>
import { GGanttChart, GGanttRow } from 'vue-ganttastic';

export default {
    name: 'BT-Gantt-Chart',
    components: {
        GGanttChart,
        GGanttRow
    },
    data: function() {
        return {

        }
    },
    props: {
        chartEnd: null,
        chartStart: null,
        dark: {
            type: Boolean,
            default: true
        },
        dateFormat: {
            type: String,
            default: 'DD/MM/YYYY HH:mm'
        },
        grid: {
            type: Boolean,
            default: true
        },
        hideTimeaxis: {
            type: Boolean,
            default: false
        },
        precision: {
            type: String,
            default: 'day'
        },
        rows: { //{ label, bars: [{ myStart, myEnd}]}
            type: Array,
            default: () => []
        }
    }
}
</script>